@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/error-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .img {
      width: 35%;
      height: 40%;
      flex-shrink: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 900;

      .title {
        color: #10439c;
        font-size: 7rem;
      }

      .subTitle {
        display: inline-block;
        color: #0e47a0;
        font-size: 3rem;
        text-transform: uppercase;
        margin-top: 1rem;
      }

      .text {
        font-size: 1.8rem;
        line-height: 1.3;
      }
    }

    .list {
      position: absolute;
      top: 50%;
      right: 2rem;
      list-style: none;
      padding: 0;
      transform: translateY(-50%);

      .item {
        margin-bottom: 2rem;

        text-decoration: none;
        color: #333;
        font-size: 2rem;

        .linkIcon {
          width: 2.5rem;
          height: 2.5rem;

          path {
            fill: $primary-color;
          }
        }
      }
    }
  }
}

@include mq("xs") {
  .container {
    .contentContainer {
      flex-direction: column;

      .img {
        width: 60%;
        height: 20%;
      }

      .content {
        align-items: center;

        .title {
          font-size: 4rem;
        }

        .subTitle {
          font-size: 2rem;
        }
      }
    }
  }
}

@include mq("ms") {
  .container {
    .contentContainer {
      flex-direction: column;

      .img {
        width: 50%;
        height: 25%;
      }

      .content {
        align-items: center;

        .title {
          font-size: 5rem;
        }

        .subTitle {
          font-size: 2.5rem;
        }
      }
    }
  }
}

@include mq("s") {
  .container {
    .contentContainer {
      flex-direction: column;

      .img {
        width: 40%;
        height: 25%;
      }

      .content {
        align-items: center;

        .title {
          font-size: 6rem;
        }
      }
    }
  }
}

@include mq("m") {
  .container {
    .contentContainer {
      flex-direction: column;

      .img {
        height: 25%;
      }

      .content {
        align-items: center;
      }
    }
  }
}
