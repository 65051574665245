@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  width: 100%;
  height: 25rem;
}

@include mq("xs") {
  .container {
    height: 10rem;
  }
}

@include mq("ms") {
  .container {
    height: 10rem;
  }
}

@include mq("s") {
  .container {
    height: 10rem;
  }
}
