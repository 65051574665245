@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  .title {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .filterContainer {
    margin-top: 1rem;

    li {
      &:last-child {
        .filter {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include mq("xs") {
  .container {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: solid 0.1rem $border-color;
  }
}

@include mq("ms") {
  .container {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: solid 0.1rem $border-color;
  }
}

@include mq("s") {
  .container {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: solid 0.1rem $border-color;
  }
}
