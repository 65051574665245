@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  .contentContainer {
    width: 70rem;
    max-height: 70%;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    border-radius: $modal-radius;
    background: $white-color;
    overflow: hidden;
  }
}

@include mq("xs") {
  .container {
    .contentContainer {
      width: 100%;
    }
  }
}

@include mq("ms") {
  .container {
    .contentContainer {
      width: 100%;
    }
  }
}

@include mq("s") {
  .container {
    .contentContainer {
      width: 100%;
    }
  }
}
