@import "../../styles/variables";

.image-gallery {
  width: 100%;
  height: 100%;

  .image-gallery-content {
    width: 100%;
    height: 100%;

    .image-gallery-slide-wrapper {
      width: 100%;
      height: 100%;

      .image-gallery-swipe {
        width: 100%;
        height: 100%;
      }

      .image-gallery-slides {
        width: 100%;
        height: 100%;
      }

      .image-gallery-slide {
        width: 100%;
        height: 100%;
      }

      .image-gallery-image {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: $border-radius;
      }

      .image-gallery-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.1);

        .image-gallery-svg {
          width: 3rem;
          height: 3rem;
        }

        &:hover {
          color: $primary-color;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
