@import "../../../../styles/variables";
@import "../../../../styles/responsive";

.container {
  margin-top: 0;

  .content {
    display: flex;
    justify-content: space-between;

    .child {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3.5);

      .title {
        margin-bottom: 1rem;
      }

      .imgsList {
        display: flex;
        flex-wrap: wrap;

        .imgItem {
          width: 100%;
          height: auto;
          margin-right: 1rem;
          margin-bottom: 1rem;

          &:nth-child(3) {
            height: 2.5rem;
          }
        }
      }

      .deliveryImgs {
        .imgItem {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@include mq("xs") {
  .container {
    .content {
      flex-direction: column;

      .child {
        width: 100%;
        margin-bottom: 1rem;

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@include mq("ms") {
  .container {
    .content {
      flex-direction: column;

      .child {
        width: 100%;

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@include mq("s") {
  .container {
    .content {
      flex-wrap: wrap;

      .child {
        width: calc(100% / 2.2);

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 1rem;
        }

        &:nth-child(2) {
          transform: translateX(0);
        }
      }
    }
  }
}
