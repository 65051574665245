.container {
  i {
    margin-right: 0.5rem;
    animation: spinner 1s linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
