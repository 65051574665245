@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  .bodyContainer {
    display: flex;
    justify-content: flex-end;

    .contentContainer {
      flex: 1;
      max-width: calc(100% - 24rem);
      height: 100%;

      .content {
        width: 100%;
        height: 100%;
        padding-left: 1rem;
        background-color: $primary-background-color;

        .bannerSlider {
          width: 100%;
          height: 100%;
        }

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: auto;

          .smallBanner {
            flex: 1;
            height: 100%;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@include mq("xs") {
  .container {
    .bodyContainer {
      .contentContainer {
        min-width: 100%;

        .content {
          padding-left: 0;

          .bannerSlider {
            width: 100%;
          }

          .banner {
            height: 15rem;
          }

          .header {
            height: 11rem;

            height: 10rem;
            .smallBanner {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include mq("ms") {
  .container {
    .bodyContainer {
      .contentContainer {
        min-width: 100%;

        .content {
          padding-left: 0;

          .bannerSlider {
            width: 100%;
          }

          .banner {
            height: 15rem;
          }

          .header {
            height: 11rem;

            .smallBanner {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include mq("s") {
  .container {
    .bodyContainer {
      .contentContainer {
        min-width: 100%;

        .content {
          padding-left: 0;

          .header {
            height: 12rem;
          }
        }
      }
    }
  }
}
