$breakpoints: (
  "xs": "screen and (max-width: 375px)",
  //  <  small phone
  "ms": "screen and (min-width: 376px) and (max-width: 575px)",
  //  <  normal phone
  "s": "screen and (min-width: 576px) and (max-width: 767px)",
  //  <  large phone
  "m": "screen and (min-width: 768px) and (max-width: 991px)",
  //  <  tablet small
  "md": "screen and (min-width: 992px) and (max-width: 1199px)",
  // <  tablet middle
  "ml": "screen and (min-width: 1200px) and (max-width: 1599px)",
  // <  tablet large
  "l": "screen and (min-width: 1600px)",
  // <  pc
  "xl": "screen and (min-width: 1900px)",
  // =< larger display,,,,,,,,,,,,,,,,,,,,,,,
) !default;
@mixin mq($break: xs) {
  // 's' as default break point
  @media #{map-get($breakpoints, $break)} {
    @content;
  }
}
