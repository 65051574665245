.container {
  .title {
    display: inline-block;
    min-width: 15%;
    font-weight: 600;
    white-space: nowrap;
  }

  .addressContainer {
    display: flex;
    margin-top: 1rem;

    .selectionInput {
      width: calc(100% / 3);

      &:nth-child(2) {
        margin: 0 1rem;
      }
    }
  }
}
