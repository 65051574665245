@import "../../../../styles/variables";

.container {
  .content {
    span {
      display: inline-block;
    }

    .text {
      margin: 1rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
