@import "../../../styles/variables";

.container {
  margin: 0;
  padding: 1rem 1.5rem;

  .title {
    margin-bottom: 1rem;
  }

  .searchList {
    display: flex;
    flex-wrap: wrap;
    max-height: 31rem;
    margin-top: 1rem;
    overflow-y: overlay;

    .searchItem {
      max-width: 100%;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      border-radius: $border-radius;
      border: solid 0.1rem $gray-text;
      overflow: hidden;
    }
  }
}
