@import "../../../styles/variables";
@import "../../../styles/responsive";

.container {
  position: relative;
  border-radius: $border-radius;
  background-color: $white-color;
  box-shadow: rgba(100, 100, 111, 0.2) 0 0.7rem 2.9rem 0;
  overflow: hidden;

  .content {
    .imgContainer {
      position: relative;
      width: 100%;
      max-width: 25rem;
      max-height: 25rem;
      overflow: hidden;

      .imgBtn {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .freeShipping {
        position: absolute;
        bottom: 0;
        left: 0;
        text-transform: uppercase;
        color: $price-color;
        font-weight: 600;
        padding: 0.3rem;
        padding-left: 0.5rem;
        background-color: $light-yellow-color;

        &::after {
          content: "";
          position: absolute;
          top: 0rem;
          right: -1.45rem;
          border-top: solid 2.4rem transparent;
          border-left: solid 1.5rem $light-yellow-color;
        }
      }

      .discount {
        position: absolute;
        top: 15%;
        left: 0;
        color: $price-color;
        font-weight: 600;
        padding: 0.3rem;
        background-color: #ddeef8;
        z-index: 2;
      }
    }

    .mainContent {
      padding: 0.5rem 0.5rem 1rem;

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        overflow: hidden;

        .brandAndPrice {
          display: flex;
          flex-direction: column;
          width: 100%;

          .brand {
            font-size: 1.3rem;
            color: $primary-color;
          }

          .priceContainer {
            .price {
              color: $price-color;
              font-weight: 600;
            }

            .retailPrice {
              position: relative;
              display: inline-block;
              font-size: 1.3rem;
              font-weight: 600;
              color: $gray-text;
              margin-right: 0.5rem;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 0.1rem;
                background-color: $gray-text;
              }
            }
          }
        }
      }

      .name {
        display: -webkit-box;
        font-size: 1.4rem;
        text-align: left;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        visibility: visible;
        text-overflow: ellipsis;
        margin: 0.5rem 0;
        overflow: hidden;
      }

      .sold {
        font-size: 1.2rem;
        color: $sold-color;
      }

      .bestBuy {
        font-size: 1.3rem;
        color: $sold-color;
      }
    }

    .gift {
      align-items: start;
      font-size: 1.3rem;
      color: $primary-color;
      margin-top: 0.5rem;

      .giftImg {
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
      }
    }

    .combo {
      color: $combo-color;
    }
  }
}
