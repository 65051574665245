.container {
  display: flex;
  align-items: center;
  width: 100%;

  .leftIconContainer {
    display: flex;
    align-items: center;
  }

  .input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-size: 1.4rem;
    border: none;
    background: transparent;
    outline: none;
  }

  .icon {
    width: 2.2rem;
  }

  .rightIconContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.errorBorder {
  border: solid 0.2rem red;
}
