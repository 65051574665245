@import "../../../styles/variables";

.container {
  display: flex;
  align-items: center;
  margin-top: 2rem;

  .title {
    display: inline-block;
    min-width: 20%;
    font-weight: 600;
    white-space: nowrap;
  }

  .input {
    border-radius: 0.4rem;
    border: solid 0.1rem $border-color;
  }
}
