@import "../../../styles/variables";
@import "../../../styles/responsive";

.container {
  padding-right: 0;
  overflow-y: overlay;

  .title {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .menuBtn {
    font-size: 2rem;
    color: #00A2FF;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $modal-background-color;
    z-index: 999;
    will-change: opacity;

    .contentContainer {
      width: 75%;
      height: 100%;
      background-color: $white-color;
      overflow: overlay;
      will-change: transform;

      .login {
        padding: 2rem !important;
        border-bottom: solid 0.1rem $border-color;

        .loginBtn {
          button {
            padding: 0;
            font-size: 1.8rem;
          }

          .profile {
            flex-direction: column;
            align-items: flex-start;
            font-weight: 600;
            color: $black-color;

            img {
              width: 5rem;
              height: 5rem;
              border-radius: 50%;
              margin-bottom: 0.5rem;
            }
          }
        }

        .menu {
          margin-top: 2rem;

          .btn {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;

            &:last-child {
              margin-bottom: 0.5rem;
            }

            &:first-child,
            &:last-child {
              i {
                margin-right: 1.4rem;
              }
            }

            i {
              margin-right: 1rem;
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }

  .fadeIn {
    display: block;
    animation: fadeIn 0.5s ease;
  }

  .fadeOut {
    display: none;
    animation: fadeOut 0.5s ease;
  }

  .moveIn {
    animation: moveIn 0.5s ease;
  }

  .moveOut {
    animation: moveOut 0.5s ease;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes moveIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@include mq("xs") {
  .leftSide {
    display: block;
    width: 100%;
  }
}

@include mq("ms") {
  .leftSide {
    display: block;
    width: 100%;
  }
}

@include mq("s") {
  .leftSide {
    display: block;
    width: 100%;
  }
  
}
