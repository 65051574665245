.container {
  .title {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .list {
    .item {
      font-size: 1.4rem;
      padding: 0.5rem 0;

      .icon {
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
      }
    }
  }
}
