@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  width: 24rem;
  overflow-y: overlay;
}

.fixedContainer {
  position: fixed;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@include mq("xs") {
  .container {
    display: none;
  }
}

@include mq("ms") {
  .container {
    display: none;
  }
}

@include mq("s") {
  .container {
    display: none;
  }
}
