@import "../../../styles/variables";
@import "../../../styles/responsive";

.container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 0.1rem $border-color;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }

  .img {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }

  .content {
    flex: 1;
    font-size: 1.4rem;

    .name,
    .gift {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      visibility: visible;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .giftColor {
      color: $primary-color;
    }

    .comboColor {
      color: $combo-color;
    }
  }
}
