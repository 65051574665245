@import "./variables";
@import "./responsive";

.generalContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-top: 10rem;
  background: $primary-background-color;
  overflow: overlay;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.body {
  width: 100%;
}

.section {
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: $border-radius;
  background-color: $white-color;
  // box-shadow: rgba(0, 0, 0, 0.25) 0 0.4rem 0.4rem 0;
}

.content {
  width: 95%;
  padding: 1rem 0;
  margin: 0 auto;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
  display: inline-block;
}

// Input
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Normal Button
.normalBtn {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 3rem;
  margin-top: 3rem;
  border-radius: 1rem !important;
  background-color: $primary-color !important;
}

// Options Selector
.optionsSelect {
  width: 100%;
  font-size: 1.4rem;
  border: solid 0.1rem #bdc0c2;
  border-radius: 0.5rem;
}

// Sign Container
.signContainer {
  display: flex;
}

.signChildren {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.signTitle {
  font-size: 2.5rem;
  font-weight: 700;
}

.signText {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $sign-color-text;
}

.signInput {
  &::placeholder {
    color: $sign-color-text;
  }
}

.signInputIcon {
  color: $sign-color-text;
}

.signBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  padding: 1.2rem 1rem;
  border-radius: 1rem;
  background: #6a6464;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

// Show & hide Left Side in Tablet & Mobile
.leftMenu {
  display: none;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  padding-left: 0;
  border-radius: 0.5rem;
  z-index: 2;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
}

// Modal
.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $modal-background-color;
  z-index: 20;
}

.modalContentContainer {
  position: relative;
}

.modalContent {
  height: 100%;
  padding: 4rem 0 0;
  overflow: overlay;
}

.modalHeader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 2rem;
  border-bottom: solid 0.1rem $border-color;
  background-color: $white-color;
  z-index: 2;

  .modalTitle {
    font-weight: 700;
  }

  .modalCloseBtn {
    position: absolute;
    top: 25%;
    right: 2%;
    font-size: 2rem;
  }
}

.modalOpacity {
  animation: opacity 0.3s ease;
}

.modalFadeIn {
  animation: fadeIn 0.3s ease;
}

// Footer
.footerSectionContainer {
  padding: 2rem;
  margin-top: 0.5rem;
  background-color: $white-color;
}

.footerText {
  font-size: 1.3rem;
}

// Policy

.policyContent {
  max-width: 70%;
}

.policyBigTitle {
  color: $primary-color;
  text-align: center;
  margin-bottom: 5rem;
}

.policyItem {
  span,
  li,
  div {
    line-height: 2;
  }
}

.policyTitle {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.policyText {
  display: inline-block;
  margin-bottom: 1rem;
}

.policySubText {
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.policySubTextItem {
  margin-bottom: 1rem;
  margin-left: 2rem;
}

// Profile
.profileTitle {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 1rem;
}

// Order
.pending {
  color: $light-yellow-color;
}

.successful {
  color: $combo-color;
}

.canceled {
  color: $price-color;
}

// Tabs List
.tabsList {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid 0.1rem $border-color;

  .tabsItem {
    white-space: nowrap;
  }

  .tabsItemActivated {
    color: $primary-color;

    path {
      fill: $primary-color;
    }
  }
}

// Mobile Category
.mobileCategory {
  display: none;
  padding: 0.5rem 0;
  margin-top: 1rem;

  & > span {
    display: none !important;
  }

  .mobileCateList {
    display: flex;
    width: 100%;
    overflow-x: overlay;

    .mobileCateItem {
      flex-direction: column;
      align-items: center;
      min-width: calc(100vw / 3.5);

      span {
        width: 90%;
        text-align: center;
        margin-top: 0.5rem;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include mq("xs") {
  .generalContainer {
    padding-top: 12.5rem;

    .content {
      width: 100%;
      padding: 1rem;
    }
  }

  .policyContent {
    max-width: 100%;
  }

  .mobileCategory {
    display: block;
  }
}

@include mq("ms") {
  .generalContainer {
    padding-top: 12.5rem;

    .content {
      width: 100%;
      padding: 1rem;
    }
  }

  .mobileCategory {
    display: block;
  }

  .policyContent {
    max-width: 100%;
  }
}

@include mq("s") {
  .generalContainer {
    padding-top: 12.5rem;

    .content {
      width: 100%;
      padding: 1rem;
    }
  }

  .policyContent {
    max-width: 100%;
  }
}

@include mq("m") {
  .content {
    width: 100%;
    padding: 1rem;
  }
}

@include mq("md") {
  .content {
    width: 100%;
    padding: 1rem;
  }
}

@include mq("l") {
  .generalContainer {
    .body {
      width: 130rem;
      margin: 0 auto;
    }
  }
}
