@import "../../../../styles/variables";
@import "../../../../styles/responsive";

.container {
  .contentContainer {
    min-height: 50%;
    height: 100%;

    .btnContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    .addressChoice {
      margin-top: 2rem;
    }
  }

  .contentFullHeight {
    min-height: 80%;
  }
}

@include mq("xs") {
  .container {
    .contentFullHeight {
      min-height: 100%;
    }
  }
}

@include mq("ms") {
  .container {
    .contentFullHeight {
      min-height: 100%;
    }
  }
}
