@import "../../../../styles/variables";
@import "../../../../styles/responsive";

.container {
  background-color: $primary-color !important;
  margin-top: 0 !important;

  .content {
    display: flex;
    justify-content: space-between;
    color: $white-color;

    .child {
      width: calc(100% / 3.3);
    }

    .partOne {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .logoContainer {
        .logoImg {
          width: 52%;
          background-color: white;
          border-radius: 20px;
          margin-bottom: 30px;
        }
      }

      .slogan {
        width: 75%;
      }
    }

    .partTwo {
      display: flex;
      justify-content: space-between;
      transform: translateX(-20%);

      .infoList {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: -1.5rem;

        .infoItem {
          margin-top: 1.5rem;
          color: $white-color;

          padding: 0;
        }

        .infoLink {
          color: $white-color;
        }
      }
    }

    .partThree {
      .linkList {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        .socialBtn {
          margin-right: 2rem;

          .socialIcon {
            path {
              fill: $white-color;
            }
          }
        }
      }

      .title {
        display: inline-block;
        margin: 2rem 0;
      }

      .email {
        display: flex;
        border: solid 0.1rem #d9d9d9;

        .input {
          input {
            color: $white-color;
          }
        }

        .submitBtn {
          font-weight: 600;
          padding: 0 2rem;
          background-color: rgba(225, 213, 213, 0.7);
        }
      }
    }
  }
}

@include mq("xs") {
  .container {
    .content {
      flex-direction: column;

      .child {
        width: 100%;
      }

      .partOne {
        display: none;
      }

      .partTwo {
        transform: translateX(0);
        margin-bottom: 1rem;
      }
    }
  }
}

@include mq("ms") {
  .container {
    .content {
      flex-direction: column;

      .child {
        width: 100%;
      }

      .partOne {
        display: none;
      }

      .partTwo {
        transform: translateX(0);
        margin-bottom: 1rem;
      }
    }
  }
}

@include mq("s") {
  .container {
    .content {
      .child {
        width: calc(100% / 2);
      }

      .partOne {
        display: none;
      }

      .partTwo {
        transform: translateX(0);
      }
    }
  }
}

@include mq("m") {
  .container {
    .content {
      .child {
        width: calc(100% / 2);
      }

      .partOne {
        display: none;
      }

      .partTwo {
        justify-content: flex-start;
        transform: translateX(0);

        .infoList {
          &:first-child {
            margin-right: 5rem;
          }
        }
      }
    }
  }
}

@include mq("md") {
  .container {
    .content {
      .child {
        width: calc(100% / 3);
      }
    }
  }
}
