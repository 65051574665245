@import "../../styles/variables";

.container {
  .cartBtn {
    position: relative;


    .cartIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .cartNumber {
    position: absolute;
    top: -30%;
    right: -25%;
    color: $white-color;
    font-size: 1rem;
    text-align: center;
    padding: 0.3rem;
    background-color: red;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
  }
}
