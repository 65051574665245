.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  .text {
    flex: 1;
    text-align: left;
    color: black;
    margin-left: 5px;
  }
}
