@import "../../../../styles/variables";

.container {
  .content {
    span {
      display: inline-block;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;

        .btn {
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}
