$black-color: #000;
$white-color: #fff;
$light-yellow-color: #dfca73;
$second-color-text: #8098f9;
$sign-color-text: #71717a;
$info-color-text: #4d4d4d;
$gray-text: #9e9e9e;
$primary-color: #00a2ff;
$sign-background-color: #f5f8ff;
$primary-background-color: #e7f2ff;
$border-color: #dadada;
$btn-color: #303030;
$price-color: #e96363;
$combo-color: #16c547;
$sold-color: #fc954b;
$modal-background-color: #53535380;
$border-radius: 2rem;
$modal-radius: 2rem;
