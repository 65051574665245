@import "../../styles/variables";
@import "../../styles/responsive";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #ffffff;

  .contentContainer {
    .content {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;

      .btn {
        color: #00a2ff;

        .icon {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.5rem;
        }
      }

      .cart {
        margin-left: 2rem;
        margin-right: 1rem;
      }

      .postMessage {
        display: flex;

        .saleRegistration {
          margin-right: 0.5rem;
        }
      }

      .topSection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > span {
          margin-left: 1.5rem;
          font-weight: 600;
        }

        .homeBtn {
          display: none;

          .homeIcon {
            width: 2.4rem;
            height: 2.4rem;

            path {
              fill: #00a2ff;
            }
          }
        }

        .menuContainer {
          padding: 1rem 2rem;

          .profileBtn {
            font-size: 1.4rem;
            font-weight: 600;
            color: $primary-color;
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }

            i {
              margin-right: 1rem;
            }
          }
        }

        .LeftBtnContainer {
          display: flex;
          align-items: center;

          .leftBtn {
            font-size: 1.4rem;
            color: $white-color;

            &:nth-child(even) {
              padding: 0 1rem;
              margin: 0 1rem;
              border-left: solid 0.1rem $white-color;
              border-right: solid 0.1rem $white-color;
            }
          }
        }

        .loginAndFlag {
          display: flex;
          align-items: center;

          .flagBtn {
            margin-left: 2rem;

            .flagIcon {
              width: 2rem;
              height: 2rem;
            }
          }
        }

        .cart {
          display: none;
        }
      }

      .bottomSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        .homeContainer {
          display: flex;
          align-items: center;

          .home {
            width: 12rem;
            height: 4rem;

            .homeImg {
              width: 100%;
              height: auto;
            }
          }

          .club {
            width: 3.5rem;
            height: 3.5rem;
            margin-left: 3rem;
            transform: translateY(10%);

            .clubImg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .search {
          width: 70%;
        }

        .cart {
          margin-right: 5rem;
        }
      }

      .postMessageMobile,
      .mobileMenu {
        display: none;
      }
    }
  }
}

@include mq("xs") {
  .container {
    .contentContainer {
      .content {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 1rem;
        padding-right: 1rem;

        .topSection {
          width: 100%;
          display: flex;
          justify-content: space-around;

          .homeBtn {
            display: block;
          }

          .mobileMenu {
            display: block;
          }

          .LeftBtnContainer,
          .postMessage,
          .loginAndFlag {
            display: none;
          }

          .profile,
          .flagBtn {
            display: none;
            margin-right: 0;
          }

          .cart {
            display: block;
            margin-left: 0;
            margin-right: 0.5rem;
          }
        }

        .bottomSection {
          margin: 1rem 0;

          .homeContainer {
            .home {
              width: 10rem;
              margin-right: 0.5rem;
            }

            .club {
              display: none;
            }
          }

          .search {
            flex: 1;
            padding: 0 1rem;
          }

          .cart {
            display: none;
          }
        }

        .postMessageMobile {
          display: flex;
        }
      }
    }
  }
}

@include mq("ms") {
  .container {
    .contentContainer {
      .content {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 1rem;
        padding-right: 1rem;

        .topSection {
          width: 100%;
          display: flex;
          justify-content: space-around;

          .homeBtn {
            display: block;
          }

          .mobileMenu {
            display: block;
          }

          .LeftBtnContainer,
          .postMessage,
          .loginAndFlag {
            display: none;
          }

          .profile,
          .flagBtn {
            display: none;
            margin-right: 0;
          }

          .cart {
            display: block;
            margin-left: 0;
            margin-right: 0.5rem;
          }
        }

        .bottomSection {
          margin: 1rem 0;

          .homeContainer {
            .home {
              width: 10rem;
              margin-right: 0.5rem;
            }

            .club {
              display: none;
            }
          }

          .search {
            flex: 1;
            padding: 0 1rem;
          }

          .cart {
            display: none;
          }
        }

        .postMessageMobile {
          display: flex;
        }
      }
    }
  }
}

@include mq("s") {
  .container {
    .contentContainer {
      .content {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 1rem;
        padding-right: 1rem;

        .topSection {
          width: 100%;
          display: flex;
          justify-content: space-around;

          .homeBtn {
            display: block;
          }

          .mobileMenu {
            display: block;
          }

          .LeftBtnContainer,
          .postMessage,
          .loginAndFlag {
            display: none;
          }

          .profile,
          .flagBtn {
            display: none;
            margin-right: 0;
          }

          .cart {
            display: block;
            margin-left: 0;
            margin-right: 0.5rem;
          }
        }

        .bottomSection {
          margin: 1rem 0;

          .homeContainer {
            .home {
              width: 10rem;
              margin-right: 0.5rem;
            }

            .club {
              display: none;
            }
          }

          .search {
            flex: 1;
            padding: 0 1rem;
          }

          .cart {
            display: none;
          }
        }

        .postMessageMobile {
          display: flex;
        }
      }
    }
  }
}

@include mq("m") {
  .container {
    .contentContainer {
      .content {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

@include mq("md") {
  .container {
    .contentContainer {
      .content {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}
