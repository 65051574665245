@import "../../styles/variables";

.container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;

  .title {
    margin-left: 2.5rem;
  }

  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 0.3rem;
    border: solid 0.1rem $border-color;

    &::after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkMark {
    &::after {
      display: block;
    }
  }

  .checkMark {
    &::after {
      left: 0.6rem;
      top: 0.1rem;
      width: 0.5rem;
      height: 1rem;
      border: solid 0.1rem $primary-color;
      border-width: 0 0.2rem 0.2rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
