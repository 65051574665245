@import "../../../styles/variables";

.container {
  .profile {
    white-space: nowrap;
    color: black;
    font-size: 1.4rem;

    .avatar {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  .loginBtn {
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 999rem;
    background-color: #00A2FF;
  }
}
