.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
