@import "./styles/variables";
@import "./styles/responsive";
@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap");

@font-face {
  font-family: "Tahoma";
  src: local("Tahoma"),
    url("./assets/fonts/Tahoma-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  font-family: "Tahoma";
  -webkit-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.5rem;
}

button {
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

textarea {
  width: 100%;
  font-size: 1.4rem;
  padding: 1rem;
  border-radius: 0.5rem;
  resize: none;
  border: none;
  outline: none;
}

a {
  display: inline-block;
  color: $black-color;
  text-decoration: none;
}

p,
span {
  line-height: 130%;
}

// Scroll Bar
html *::-webkit-scrollbar {
  display: none;
}
/* Track */
html *::-webkit-scrollbar-track {
  border-radius: 0.1rem;
}

/* Handle */
html *::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.3);
  border-radius: 0.1rem;
}

/* Handle on hover */
html *::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0, 0.5);
}

.container {
  background-color: $primary-background-color;
}
