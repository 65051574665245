@import "../../styles/variables";
@import "../../styles/responsive";

.btn {
  font-size: 1.8rem;
  font-weight: 600;
  color: $white-color;
  white-space: nowrap;
  padding: 1rem 3rem;
  border-radius: $border-radius;
  background-color: $price-color;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.4rem 0.4rem 0;
}
