@import "../../../../styles/variables";

.container {
  .content {
    .info {
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }

      .title {
        font-weight: 600;
      }
    }
  }
}
