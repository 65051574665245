@import "../../../styles/variables";
@import "../../../styles/responsive";

.container {
  .inputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    background: #E7F2FF;
    padding: 0.2rem 1rem;

    .searchBtn {
      padding: 0;
    }

    .loading,
    .clear {
      color: #9b9595;
      font-size: 1.8rem;
      line-height: 0;
      padding: 0;
    }

    .loading {
      cursor: default;
      animation: spinner 0.8s linear infinite;
    }
  }

  .list {
    width: 60vw;
    max-height: 50rem;
    padding: 1rem;
    overflow: overlay;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@include mq("xs") {
  .list {
    width: 80vw;
    max-height: 40rem;
  }
}

@include mq("ms") {
  .list {
    width: 75vw;
    max-height: 40rem;
  }
}
