@import "../../../styles/variables";
@import "../../../styles/responsive";

.container {
  .section {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titleContainer {
        display: flex;
        align-items: center;

        .titleIcon {
          width: 3rem;
          height: 3rem;
          margin-right: 0.5rem;
        }
      }

      .moreBtn {
        color: $primary-color;

        .moreText {
          display: inline-block;
          margin-right: 1rem;
        }
      }
    }

    .productList {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem 0;

      .productItem {
        width: calc(100% / 5.3);
        max-width: 20rem;
        margin: 0.5rem;
      }
    }
  }
}

@include mq("xs") {
  .container {
    .section {
      .header {
        .titleContainer {
          .titleIcon {
            width: 2.5rem;
            height: 2.5rem;
          }

          .title {
            font-size: 1.6rem;
          }
        }
      }

      .moreBtn {
        position: absolute;
        bottom: 16%;
        right: 12%;
      }

      .productList {
        justify-content: space-between;

        .productItem {
          width: 48%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

@include mq("ms") {
  .container {
    .section {
      .header {
        .titleContainer {
          .titleIcon {
            width: 2.5rem;
            height: 2.5rem;
          }

          .title {
            font-size: 1.6rem;
          }
        }
      }

      .moreBtn {
        position: absolute;
        bottom: 16%;
        right: 12%;
      }

      .productList {
        justify-content: space-between;

        .productItem {
          width: 48%;
          max-width: 25rem;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

@include mq("s") {
  .container {
    .section {
      .productList {
        .productItem {
          width: calc(100% / 3.2);
        }
      }
    }
  }
}

@include mq("m") {
  .container {
    .section {
      .productList {
        .productItem {
          width: calc(100% / 3.2);
        }
      }
    }
  }
}
